.green {
    width: 1em;
    height: 1em;
    display: inline;
    background-color: green;
}

.dropdownList {
    padding: 0;
    margin: 0;
    width: 100%;
    border-spacing: 0;
    height: 400px;
    overflow-y: scroll;
    overflow-x: scroll;
}

.dropdownList div {
    padding: 0.2em;
    display: flex;
    align-items: center;

    height: 2.5em;

    box-sizing: border-box;
    border: 1px solid darkgrey;
    border-bottom: none;
}

.dropdownList div .selector {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.2em 0.4em 0.2em 0;
    border-radius: 0.5em;
}

.dropdownList div .spacer {
    padding: 0.2em;
}

.dropdownList div:last-child {
    border-bottom: 1px solid darkgrey;
}

.dropdownList div:first-child {
    border-bottom: 1px solid darkgrey;
}

.dropdownList .labelsHeader {
    border-left: none;
    border-right: none;
    border-top: none;
    height: 2em;
    display: flex;
}

.spacer {
    flex-grow: 1;
}

.label {
    flex-grow: 1;
}

.colorSquare {
    width: 1.5em;
    height: 1.5em;
    border: 1px solid darkgrey;
}

.dropdownList div .selector:hover {
    color: white;
    background-color: darkgrey;
}

.selected {
    background-color: #505050;
    color: white;
}

.attributes {
    display: flex;
    margin-left: 5px;
    margin-right: 5px;
}

.attributeSelector {
    display: flex;
    align-items: center;
}

.moves {
    margin-left: 5px;
    margin-right: 5px;
}
