.shortcutIcon {
    display: inline-block;
    min-width: 1em;
    height: 1em;
    border: 1px solid darkgrey;
    border-radius: 3px;
    text-align: center;
    line-height: 100%;
    margin: 0 0.5em 0 0.35em;
}
