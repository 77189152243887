.inputDial {
    display: flex;
    justify-content: space-around;
}

.inputDial input {
    width: 40%;
}

.inputDial button {
    margin-left: 0.25em;

    width: 1.5em;
    height: 1.5em;

    border-radius: 0;
    outline: none;
    text-align: center;
    vertical-align: middle;
    border-style: none;

    background-color: #ddd;
}

.inputDial button.uniform {
    width: 3em;
}

.inputDial button:hover {
    cursor: pointer;
    filter: brightness(85%);
}

.inputDial .raised {
    box-shadow: 2px 2px 12px -3px #333;
}
