.container {
    border: 1px solid darkgrey;
    border-radius: 0.5em;
    margin: 0.5em;
    padding: 1em;
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    z-index: 5;
}

.container p {
    margin: 0;
    text-align: right;
}

.invisible {
    display: none;
}
