.text-checkbox {
    display: inline-block;
    text-decoration: line-through;
    margin-right: 3pt;
    user-select: none;
    padding: 0.2em;
}

.text-checkbox:hover {
    cursor: pointer;
    background-color: darkgrey;
    border-radius: 0.5em;
}

.text-checkbox.checked {
    text-decoration: none;
}
