html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

#root {
    height: 100%;
    display: flex;
}

.full-height {
    height: 100%;
}
