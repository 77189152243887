.container {
    height: 1.5em;
    width: 100%;
    background-color: lightgray;
    border-radius: 1em;
    margin-bottom: 1em;
    margin-top: 0;
}

.filler {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    background-color: blue;
    border-radius: inherit;
    text-align: right;
}

.label {
    padding: 5px;
    color: white;
    font-weight: bold;
}
