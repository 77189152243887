.downloadButton {
    margin-right: 1em;
}

.filenameDisplay {
    height: 20px;
    max-width: 320px;
    overflow-x: scroll;
    white-space: nowrap;
}

.filenameTextInput {
    border: 1px solid #999;
    padding: 0.5rem;
    width: 300px;
}

.bucketMessage p {
    width: 300px;
    font-size: 10pt;
    margin: 0;
}
