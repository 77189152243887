.metadata {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    width: 100%;
    border-spacing: 0;
}

.metadata div {
    display: flex;
    align-items: center;
    padding: 0 4px 0 0;
}
.metadata span {
    padding: 2px;
}
