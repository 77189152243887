.attribute-select {
    position: relative;
}

.attribute-select-button {
    white-space: nowrap;
    background-color: white;
    padding: 5px;
    vertical-align: top;
    box-shadow: 2px 1px 2px gray;
    margin: 4px 10px 4px 10px;
    width: 80px;
    text-align: left;
    cursor: pointer;
}

.attribute-select-button.active {
    background-color: lightgrey;
    box-shadow: 0 0 0 white;
    margin: 6px 10px 2px 10px;
}

.attribute-select-dropdown {
    z-index: 10;
    cursor: default;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    background-color: white;
    color: black;
    left: 100px;
    top: 0px;
    padding: 10px;
    border: 1px solid black;
}

.attribute-select-dropdown-item {
    display: block;
    cursor: pointer;
    white-space: nowrap;
}

.attribute-select-dropdown-item:hover {
    background-color: lightgrey;
    cursor: pointer;
}

.attribute-select-dropdown.hidden {
    display: none;
}
